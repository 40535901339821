.projects__grid {
  max-width: 95%;
  margin: 5rem auto;
  align-items: center;
  border-radius: 1em;
  box-shadow: var(--shadow);
  display: flex;
  justify-content: space-between;
  padding: 2em;
  text-align: center;
}
