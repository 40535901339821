.project {
  flex-basis: 50%;
  margin-left: 2em;
}

.project__description {
  margin-top: 1em;
}

.project__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.project__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.project .link--icon {
  margin-left: 0.5em;
}

.projects__grid img {
  border-radius: 0.5em;
  width: 50%;
  object-position: top;
  object-fit: cover;
}
@media (max-width: 430px) {
  .project__description {
    display: none;
  }
}

@media (max-width: 768px) {
  .projects__grid {
    flex-direction: column;
  }

  .projects__grid img {
    margin: 0 auto 2em;
    width: 75%;
  }

  .project {
    width: 75%;
  }
}
