.skills {
  overflow: visible !important;
}

.skills p {
  margin: 0 auto 2em;
  text-align: center;
  max-width: 1000px;
}

.skills__list {
  margin: 0 auto;
  display: flex !important;
  gap: 1em;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.skills__list-item {
  width: 10em;
  height: 10em;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1.5em;
  box-shadow: var(--shadow);
  overflow: hidden;
  font-size: 0.8rem;
  font-weight: 500;
}

.skills__list-item i {
  font-size: 3rem;
  margin-bottom: 0.2em;
}
