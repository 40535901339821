.about {
  width: 100%;
  justify-items: center;
  margin-top: 2em;
  margin-bottom: 10em;
  height: 400px;
  justify-content: center;
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 500px;
}

.about__buttons {
  flex-direction: row;
  /* flex-wrap: inherit; */
  display: flex;
  grid-gap: 1em;
  gap: 1em;
}

.about__buttons a {
  text-align: center;
  flex: 0 0 30%;
  max-width: 30%;
  margin-top: 2.5em;
}

.about__desc {
  font-size: 1rem;
  max-width: 600px;
  margin-left: 50px;
}

.about__role {
  margin-bottom: 1.5em;
}

.about__role,
.about__desc,
.about__contact {
  margin-top: 1.5em;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}

.about__desc {
  line-height: 2em;
  animation: fadeIn 1s 4s backwards;
}

@media only screen and (max-width: 768px) {
  .app .about {
    margin-left: 5px;
    justify-items: flex-start;
    align-items: center;
    margin-top: 2em;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 100%;
  }

  .about__desc {
    margin-left: 0;
    max-width: 100%;
  }

  .about__titles {
    max-width: 100%;
  }

  .about__buttons a {
    flex: 0 0 40%;
    max-width: 40%;
  }
}
@media only screen and (max-width: 430px) {
  .app .about {
    align-items: flex-start;
  }

  .about__desc {
    max-width: 100%;
    margin-top: 0;
  }

  .about__titles {
    max-width: 100%;
  }

  .about__buttons a {
    flex: 0 0 40%;
    max-width: 40%;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
