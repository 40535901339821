.contact {
  flex-direction: column;
}

.contact__content {
  display: flex;
  flex-direction: row;
  margin-top: 2em;
  width: 95%;
}

.left {
  flex: 0 0 40%;
  max-width: 40%;
  padding: 1em;
  margin-top: 20px;
  margin-left: 5em;
}

.contact__title {
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 400;
}

.info__item {
  display: flex;
  margin: 50px 0px;
  width: 80%;
  align-items: flex-start;
}

.info__item svg {
  color: var(--clr-primary);
  scale: 1.5;
}

.desc {
  font-size: 20px;
  margin-bottom: 5px;
  font-weight: bolder;
}

.right {
  flex: 0 0 60%;
  max-width: 60%;
  padding: 1em;
  margin-top: 20px;
}

#contact-form {
  margin-top: 20px;
}

input {
  width: 98%;
  height: 50px;
  box-shadow: var(--shadow);
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
}

input:nth-child(-n + 2) {
  width: 48%;
  margin-right: 10px;
}

textarea {
  width: 98%;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
  padding-top: 10px;
  box-shadow: var(--shadow);
}

#contact-form button {
  margin: 10px 0px;
}

@media (max-width: 768px) {
  .contact__content {
    flex-direction: column;
  }

  .left {
    margin-left: 0;
  }

  .left,
  .right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 430px) {
  input {
    width: 100% !important;
  }
}
