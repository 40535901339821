.logo {
  display: block;
  width: 500px;
  margin: auto;
}

.logo-container {
  overflow: hidden;
  margin: auto;
  width: 100vw;
  height: 100vh;
  background-color: var(--clr-bg);
}
.logo-container .path {
  stroke-dasharray: 2835;
  stroke-dashoffset: 2835;
  animation: dash 1s 1s linear forwards;
  filter: drop-shadow(0px 0px 3px var(--clr-primary));
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@media (max-width: 430px) {
  .logo {
    width: 400px;
  }
}
